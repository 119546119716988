/* eslint max-len: 0 */
import { getLogger } from '@cld/upload-widget-common';
import { createElement, getElement } from './utils';

const logger = getLogger();

export default () => {
  try {
    const style = createElement('style', {
      id: 'cloudinary-uw-page-styles',
      type: 'text/css',
    });

    style.innerHTML = `.cloudinary-thumbnails { list-style: none; margin: 10px 0; padding: 0 }
        .cloudinary-thumbnails:after { clear: both; display: block; content: '' }
        .cloudinary-thumbnail { float: left; padding: 0; margin: 0 15px 5px 0; display: none }
        .cloudinary-thumbnail.active { display: block }
        .cloudinary-thumbnail img { border: 1px solid #01304d; border-radius: 2px; -moz-border-radius: 2px; -webkit-border-radius: 2px }
        .cloudinary-thumbnail span { font-size: 11px; font-family: Arial, sans-serif; line-height: 14px; border: 1px solid #aaa; max-width: 150px; word-wrap: break-word; word-break: break-all; display: inline-block; padding: 3px; max-height: 60px; overflow: hidden; color: #999; }
        .cloudinary-delete { vertical-align: top; font-size: 13px; text-decoration: none; padding-left: 2px; line-height: 8px; font-family: Arial, sans-serif; color: #01304d }
        .cloudinary-button { background-color: #0078FF; color: #FFFFFF; text-decoration: none; font-size: 14px; line-height: 28px; height: 28x; cursor: pointer; font-weight: normal; display: inline-block; border-radius: 4px; padding: 10px 14px;}
        .cloudinary-button:hover {-webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5); box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5); } `;

    const head = getElement('head');

    if (head) {
      head.appendChild(style);
    }
  } catch (e) {
    logger.error('[all.pageStyles]: failed to apply styles');
  }
};
