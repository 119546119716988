import { getLogger, MESSAGE_TYPES } from '@cld/upload-widget-common';
import { isString, isFile } from './utils';

const logger = getLogger();

let batchIdCounter = 0;

export default (comms) => {
  const reportFileDataForUpload = (fileInfo, batchId) => {
    const { file, index, count } = fileInfo;

    comms.sendMessage(
      MESSAGE_TYPES.FILE,
      {
        file,
        index,
        count,
        batchId,
      },
      true
    );
  };

  const processFile = (file, index, count, options, batchId) => {
    let promise;

    if (!options.maxFileSize || (options.maxFileSize > 0 && file.size <= options.maxFileSize)) {
      const fileInfo = { file, index, count };
      promise = reportFileDataForUpload(fileInfo, batchId);
    } else {
      logger.log('[global.all.uploadsHandler]: provided file is larger than max file size configured', file.size);
    }

    return promise;
  };

  // wait for first file to read until we resolve
  const processFilesData = (files, options, batchId) =>
    Promise.race(
      Array.prototype.map.call(files, (f, index) => {
        // in case its FileList
        let promise = null;

        if (isFile(f)) {
          promise = processFile(f, index, files.length, options, batchId);
        } else if (isString(f)) {
          comms.sendMessage(MESSAGE_TYPES.FILE, {
            file: f,
            index,
            count: files.length,
            batchId,
          });
        } else {
          logger.warn('[global.all.uploadsHandler]: unknown type of object sent to upload', f);
        }

        return promise;
      })
    );

  const startFilesUpload = (files, batchOptions, options) => {
    batchIdCounter += 1;
    const batchId = `batch_${batchIdCounter}`;

    // send a message to the uploader to create a new batch - before any file has been processed
    comms.sendMessage(MESSAGE_TYPES.CLIENT_BATCH, {
      batchId,
      batchOptions,
      count: files.length,
    });

    return processFilesData(files, options, batchId);
  };

  const handleFiles = (showOptions, options) =>
    showOptions && showOptions.files && showOptions.files.length
      ? // found files in the options, need to process them
        startFilesUpload(showOptions.files, showOptions.batchOptions, options)
      : Promise.resolve();

  return {
    handleFiles,
  };
};
